import React from "react";
import Parallax from "components/Contester/Parallax/Parallax.js"
import {Divider,Col,Typography,Row,Card,Carousel} from "antd";

import CookieConsent from "react-cookie-consent";
import Carusel from "components/Contester/Carousel/Carousel.js";
const { Title } = Typography;
class HomePage extends React.Component{
  constructor(props) {
    super(props);
  this.state={
    windowHeight: 0,
    windowWidth: 0,

  }
}

updateWindowDimension = () => {
  this.setState(
    {windowHeight: window.innerHeight,
      windowWidth: window.innerWidth}
  );
};

componentDidMount() {
  this.updateWindowDimension();
  window.addEventListener("resize", this.updateWindowDimension);
}

componentWillUnmount() {
  window.removeEventListener("resize", this.updateWindowDimension);
}
  render(){
    if(this.state.windowWidth <= 950){
  return (
    <div>
     <Parallax/> <br/><br/>
    <Row justify="center" gutter={32}>
      <Divider  style={{ color: '#333', fontWeight: 'normal' }}>
      <Title level={3} style={{textAlign:"center" ,color:"#038fde"}}>Partenerii noștrii</Title>
      </Divider>
    </Row>
    <div style={{width:this.state.windowWidth-30}}>
    <Carousel autoplay>
    <div>
    <img src={require('assets/images/logos1.png')}></img>
    </div>
    <div>
    <img src={require('assets/images/logos2.png')}></img>
    </div>
    </Carousel></div>
    <br/>
    <Row justify="center" gutter={32}>
      <Divider  style={{ color: '#333', fontWeight: 'normal' }}>
      <Title level={3} style={{textAlign:"center" ,color:"#038fde"}}>Despre noi</Title>
      </Divider><Col xl={4} xs={0}></Col>
      <Col xl={16} xs={24}>
      <div>
  <Card style={{background:	"	#DCDCDC",}}><br/><br/>
    <p style={{fontSize:18}}>
    Suntem de 20 de ani pe piață și oferim servicii de copiat acte, scanare, listare color, îndosariat lucrări, laminare și transimitere mail.
    </p><p style={{fontSize:18}}>De altfel, la noi găsiți produse de birotică, papetarie, tipizate, rechizite scoalare, totul pentru birou, accesorii și jucarii.
    </p><br/><br/>
  </Card>
  </div>
      </Col>
    </Row>
    
    </div>
  );
}
else{
  return (
    <div>
     <Parallax/> <br/><br/>
    <Row justify="center" gutter={32}>
      <Divider  style={{ color: '#333', fontWeight: 'normal' }}>
      <Title level={3} style={{textAlign:"center" ,color:"#038fde"}}>Partenerii noștrii</Title>
      </Divider>
    </Row>
    <div>
      <Carusel/>
    </div>
    <br/>
    <Row justify="center" gutter={32}>
      <Divider  style={{ color: '#333', fontWeight: 'normal' }}>
      <Title level={3} style={{textAlign:"center" ,color:"#038fde"}}>Despre noi</Title>
      </Divider><Col xl={4} xs={0}></Col>
      <Col xl={16} xs={24}>
      <div>
  <Card style={{background:	"	#DCDCDC",}}><br/><br/>
    <p style={{fontSize:18}}>
    Suntem de 20 de ani pe piață și oferim servicii de copiat acte, scanare, listare color, îndosariat lucrări, laminare și transimitere mail.
    </p><p style={{fontSize:18}}>De altfel, la noi găsiți produse de birotică, papetarie, tipizate, rechizite scoalare, totul pentru birou, accesorii și jucarii.
    </p><br/><br/>
  </Card>
  </div>
      </Col>
    </Row>
    
    </div>
  );
}}
};
    
export default HomePage;
