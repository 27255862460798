import React from "react";
import {Typography,Row,Col} from "antd";
const {Title}= Typography;
class ProdusePage extends React.Component {
  constructor(props){
    super(props);
    this.state={
      windowHeight: 0,
      windowWidth: 0,
    }
    
  }
  
  updateWindowDimension = () => {
    this.setState(
      {windowHeight: window.innerHeight,
        windowWidth: window.innerWidth}
    );
  };
  
  componentDidMount() {
    this.updateWindowDimension();
    window.addEventListener("resize", this.updateWindowDimension);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimension);
  }
  render(){
  return (
    
    <div>
      <Col xl={16} xs={24}>
      <Row justify="center" gutter={16}>
      <Title level={3}>Papetarie</Title><br/> </Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Birotică</Title><br/></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Hârtie A4/A3</Title><br/></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Tipizate</Title><br/></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Tipizate personalizate</Title><br/></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Cartele telefonice și PaySafeCard</Title><br/></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>CopyCenter</Title><br/></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Carți de cadou și pentru copii</Title></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Cartuse și tonere - Noi și Compatibile</Title></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Reîncarcare cartușe și Tonere</Title></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Servicii de laminare și scanare A4/A3</Title></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Plați facturi - fără comision</Title></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Artizanat</Title></Row>
      <Row justify="center" gutter={16}>
      <Title level={3}>Jocuri & Jucarii</Title></Row>
     </Col>
    </div>
    );
  };
};
export default ProdusePage;
