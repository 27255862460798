import React from "react";
import {Row,Col,Input,Icon,Button,Layout,Divider} from "antd";
import Map from "components/libraria/Map";
import Widget from "components/Widget";
import MailOutlineIcon from 'assets/images/mail.png';
import emailjs from 'emailjs-com';
import Recaptcha from 'reaptcha';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const {Content}= Layout;
const { TextArea } = Input;

class ContactPage extends React.Component {
constructor(props){
  super(props);

  this.onResolved = this.onResolved.bind(this);
  this.onVerify = this.onVerify.bind(this);
  this.state={
    isVerified: false,
    windowHeight: 0,
    windowWidth: 0,
    email: "",
    feedback: "",
  }
}
validateMail(email){
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
onVerify = recaptchaResponse => {
  this.setState({
    isVerified: true
  });
};
onResolved() {
  const templateParams = {
    from_name: this.state.email,
    to_name: "LibrariaTa",
    feedback: this.state.feedback
  };
  emailjs.send("service_libraria", "template_pd2m6s6", templateParams, "user_nRlsudWIp8YyDCS1mOUHL")
    .then(
      function(response) {
        toast.success("Mesajul tău a fost trimis cu succes!", {
          position: toast.POSITION.BOTTOM_CENTER
        });
      },
      function(err) {
        toast.error("Mesajul tău nu a putut fi trimis. Te rugăm să mai încerci.");
      }
    );
  this.setState({
    email: "",
    feedback: ""
  });
  this.captcha.reset();
}
sendMessage(event) {
event.preventDefault();

if (!this.validateMail(this.state.email)) {
    toast.error("Emailul introdus este incorect.");
    return;
  }
if(this.state.feedback.length<15){
  toast.error("Mesajul este prea scurt.");
  return;
}
if(!this.state.isVerified){
  toast.error("Verifică că ești om.");
  return;
}
this.onResolved();
}
updateWindowDimension = () => {
  this.setState(
    {windowHeight: window.innerHeight,
      windowWidth: window.innerWidth}
  );
};

componentDidMount() {
  this.updateWindowDimension();
  window.addEventListener("resize", this.updateWindowDimension);
}

componentWillUnmount() {
  window.removeEventListener("resize", this.updateWindowDimension);
}
handleInputChange(event) {
  event.preventDefault();
  const target = event.target;
  const name = target.name;
  const value = target.value;
this.setState({ [name]: value });
}
  render(){
    if(this.state.windowWidth <= 950){
  return (
    <Row gutter={16} type="flex" align="middle">
      <Col xl={12} md={24} sm={12} xs={24}>
      <Widget>
        <Row justify="center">
          <Col>
        <h2 style={{color:'#038fde'}}>Telefon de Contact:</h2><h3> 0212 230 088 </h3>
        <h2 style={{color:'#038fde'}}>Adresă:</h2><h3> Bd. Ion Mihalache nr 60-62 parter</h3>
        <h2 style={{color:'#038fde'}}>Program de lucru:</h2><h3>Luni-Vineri: 8:00-18:00 </h3><h3> Sambata-Duminica: 9:00-14:00 </h3>
          </Col>
       </Row>
       <br/><br/><br/>
        <Row >
          <Col>
          <h3>Ne poți adresa întrebări referitoare la servicii și produse folosind formularul de mai jos sau la adresa de email : libraria2001@yahoo.com</h3>
          <Col xs={24} xl={12}><Input 
        onChange={this.handleInputChange.bind(this)}
        id="email"
        name="email"
        value={this.state.email}
        prefix={<Icon type="mail"/>}
        placeholder="Adresa Dumneavoastră de Email" >
          </Input></Col>
        <div style={{ margin: '24px 0' }} />
        <Col span={24}><TextArea 
        onChange={this.handleInputChange.bind(this)} 
        placeholder= "Mesaj"
        id="feedback"
        name="feedback"
        value={this.state.feedback}
        rows={4}>
          </TextArea>          
          </Col>
          <Col span={24}>
            <br/>   
          <Recaptcha
          ref={e => (this.captcha = e)}
          sitekey= "6LeYJMsZAAAAAD85v22vJ697Iuz73VoZZotrL8Gu" 
          onVerify={this.onVerify}/></Col>
          <Button style={{marginTop:10,float: 'right'}} type={"primary"} onClick={this.sendMessage.bind(this)}>Trimite mail</Button>
          <Divider>Sau</Divider>
          <Col span={24} offset={5}><Button style={{marginTop:10}} type={"primary"}><a href="mailto:libraria2001@yahoo.com?Subject=Site%20Redirect">Deschide aplicatia de email</a></Button>
          </Col><ToastContainer />

       </Col>
       </Row>
      
      </Widget>
      <div style={{height:500}}>
      <Map/>
      </div><br/><br/>
      </Col>
    </Row>
  );
  }
  else{
    return (
      <div>
        <div style={{height:500}}>
        <Map/>
       </div><br/><br/>
        <Widget>
        <Row>
          <Col span={6}>
        <h2 style={{color:'#038fde'}}>Telefon de Contact:</h2><h3> 0212 230 088 </h3>
        <h2 style={{color:'#038fde'}}>Adresă:</h2><h3> Bd. Ion Mihalache nr 60-62 parter</h3>
        <h2 style={{color:'#038fde'}}>Program de lucru:</h2><h3>Luni-Vineri: 8:00-18:00 </h3><h3> Sambata-Duminica: 9:00-14:00 </h3>
        </Col>
        <Col span={18}>
        <h3>Ne poți adresa întrebări referitoare la servicii și produse folosind formularul de mai jos sau la adresa de email : libraria2001@yahoo.com</h3>

        <Col span={12}><Input 
        onChange={this.handleInputChange.bind(this)}
        id="email"
        name="email"
        prefix={<Icon type="mail"/>}
        value={this.state.email}
        placeholder="Adresa Dumneavoastră de Email" >
          </Input></Col>
        <div style={{ margin: '24px 0' }} />
        <Col span={24}><TextArea 
        onChange={this.handleInputChange.bind(this)} 
        placeholder= "Mesaj"
        id="feedback" 
        name="feedback"
        value={this.state.feedback}
        rows={4}>
          </TextArea></Col>
        
        <ToastContainer />
         <Col span={24}>
           <br/>
        <Recaptcha
          ref={e => (this.captcha = e)}
          sitekey= "6LeYJMsZAAAAAD85v22vJ697Iuz73VoZZotrL8Gu" 
          onVerify={this.onVerify}/></Col>
        <Button style={{marginTop:10,float: 'right'}} onClick={this.sendMessage.bind(this)} type={"primary"}>Trimite email</Button>
       </Col>
      </Row>
      </Widget>
      </div>
  );
}
}
};

export default ContactPage;

    