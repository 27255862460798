import React from "react";
import { Typography,Carousel } from 'antd';
const {Title}=Typography;

const CarouselComp =()=> (
<div>
<Carousel autoplay>
    <div>
    <img src={require('assets/images/logos1.png')}></img>
    </div>
    <div>
    <img src={require('assets/images/logos2.png')}></img>
    </div>
  </Carousel>,
</div>
);
export default CarouselComp;
