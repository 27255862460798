import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import "./map.css";
export class MapContainer extends Component {
  state = {
    showingInfoWindow: true,  
    activeMarker: {},          
    selectedPlace: {}          
  };
  onMarkerClick = (props, marker, e) =>
  this.setState({
    selectedPlace: props,
    activeMarker: marker,
    showingInfoWindow: true
  });

onClose = props => {
  if (this.state.showingInfoWindow) {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null
    });
  }
};
  render() {
    return (
      <div className="map">
      <Map
        google={this.props.google}
        className="map"
        zoom={20}
        style={{height:500}}
        initialCenter={{ lat: 44.458659, lng: 26.077444}}
        >
          <Marker onClick={this.onMarkerClick} position={{ lat: 44.458707, lng: 26.077408}} ></Marker>
          <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>Suntem Aici</h4>
          </div>
        </InfoWindow>
        </Map>
        </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyC7qa8uHttH8xHsIWKyJ4yRVX_l_N_vUkw'
})(MapContainer);